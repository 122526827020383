import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Alert, Spinner } from "react-bootstrap";
import SlsTitle from "./../../UI/SlsTitle";
import FilterSection from "./FilterSection";
import ProjectCard from "./../../UI/ProjectCard";

const getUniqueValues = (array, key) => {
  return [...new Set(array.map((item) => item[key]))];
};

function Projects() {
  const [projectsData, setProjectsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    type: "",
    space: "",
    surface: "",
  });

  const [typeOptions, setTypeOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [surfaceOptions, setSurfaceOptions] = useState([]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/projects`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch projects.");
        }
        return response.json();
      })
      .then((data) => {
        setProjectsData(data.projects);
        setTypeOptions(getUniqueValues(data.projects, "type"));
        setLocationOptions(getUniqueValues(data.projects, "location"));
        setSurfaceOptions(getUniqueValues(data.projects, "surface"));
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  const filteredProjects = projectsData.filter((project) => {
    return (
      (filters.type === "" || project.type === filters.type) &&
      (filters.space === "" || project.location === filters.space) &&
      (filters.surface === "" ||
        parseInt(project.surface) === parseInt(filters.surface))
    );
  });

  return (
    <Container fluid className="mb-5">
      <SlsTitle title="Nos Projets" />
      <FilterSection
        filters={filters}
        onFilterChange={handleFilterChange}
        typeOptions={typeOptions}
        locationOptions={locationOptions}
        surfaceOptions={surfaceOptions}
      />
      <div className="pt-2 px-2  px-lg-5 pb-5">
        <Row className="justify-content-center align-items-stretch">
          {loading ? (
            <Col className="text-center mt-5">
              <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          ) : error ? (
            <Col className="text-center mt-5">
              <Alert variant="danger">{error}</Alert>
            </Col>
          ) : filteredProjects.length > 0 ? (
            filteredProjects.map((project, index) => (
              <Col
                key={index}
                lg={4}
                md={6}
                sm={12}
                className="d-flex justify-content-center align-items-stretch"
              >
                <div className="d-flex flex-column" style={{ width: "100%" }}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/project-details/${project.id}`}
                  >
                    <ProjectCard
                      imageSrc={project.image_src}
                      title={project.title}
                      location={project.location}
                      type={project.type}
                      year={project.year}
                      surface={project.surface}
                    />
                  </Link>
                </div>
              </Col>
            ))
          ) : (
            <Col className="text-center mt-5">
              <Alert className="alert-danger text-danger">
                Aucun projet ne correspond aux filtres sélectionnés.
              </Alert>
            </Col>
          )}
        </Row>
      </div>
    </Container>
  );
}

export default Projects;
