import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Spinner } from "react-bootstrap";
import classes from "./ProjectDetails.module.css";
import date from "./../../assets/images/Date.svg";
import bathroom from "./../../assets/images/bathroom-tub-towel.svg";
import bed from "./../../assets/images/bed.svg";
import location from "./../../assets/images/location__.svg";
import vector from "./../../assets/images/Vector.svg";
import Arrow from "./../../assets/images/PeojectDetailsArrow.svg";
import Kitchen from "./../../assets/images/kitchen.svg";

function ProjectDetails() {
  const param = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/projects`
        );
        if (!response.ok) {
          throw new Error("Erreur lors de la récupération des projets.");
        }
        const data = await response.json();
        const filteredProject = data.projects.find(
          (proj) => proj.id === parseInt(param.id, 10)
        );
        if (filteredProject) {
          setProject(filteredProject);
        } else {
          throw new Error("Projet non trouvé.");
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [param.id]);

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <div className="text-center text-danger">Erreur : {error}</div>;
  }

  if (!project) {
    return <div className="text-center">Aucun projet trouvé.</div>;
  }

  return (
    <Row className="p-5 d-flex justify-content-center">
      <Col
        xxl={5}
        lg={6}
        md={12}
        sm={12}
        className={`d-flex 
          justify-content-start 
          position-relative 
          pt-md-2 
          ${classes.right_border}`}
      >
        <div className={classes.details_content}>
          <h2 style={{ color: "#D19B16", fontWeight: 700 }}>{project.title}</h2>
          <span className={classes.gradient_line}></span>
          <h3>{project.type}</h3>
          <div className={classes.location}>
            <img src={location} alt="location" />
            <p>{project.location}</p>
          </div>
          <div className={classes.infos1}>
            <div className={classes.info1}>
              <img src={bed} alt="room" /> {project.number_of_bedrooms}
            </div>
            <div className={classes.info1}>
              <img src={bathroom} alt="bathroom" />
              {project.number_of_bathrooms}
            </div>
            <div className={classes.info1}>
              <img src={Kitchen} alt="garage" />
              {project.number_of_kitchens}
            </div>
            <div className={classes.info1}>
              <img src={date} alt="date" />
              {project.year}
            </div>
          </div>
          <div>
            <div className={classes.info2}>
              <img src={vector} alt="vector_icon" /> Pour : 
              <span className={classes.value}>
                {project.rental_or_sale === "sale"
                  ? "Vente"
                  : project.rental_or_sale === "rent"
                  ? "Location"
                  : project.rental_or_sale === "sale_and_rent"
                  ? "Vente et location"
                  : ""}
              </span>
            </div>
            <span className={classes.greyline}></span>
            <div className={classes.info2}>
              <img src={vector} alt="vector_icon" /> Surface :
              <span className={classes.value}>{project.surface}m2</span>
            </div>
            <span className={classes.greyline}></span>
            <div className={classes.info2}>
              <img src={vector} alt="vector_icon" /> Disponible pour pret :
              <span className={classes.value}>
                {project.available_for_loan ? "Oui" : "Non"}
              </span>
            </div>
            <span className={classes.greyline}></span>
            <div className={classes.info2}>
              <img src={vector} alt="vector_icon" /> Espaces vert :
              <span className={classes.value}>
                {project.green_spaces ? "Oui" : "Non"}
              </span>
            </div>
            <span className={classes.greyline}></span>
            <div className={classes.info2}>
              <img src={vector} alt="vector_icon" /> Finition :{" "}
              <span className={classes.value}>
                {project.finishing ? "Oui" : "Non"}
              </span>
            </div>
            <span className={classes.greyline}></span>
            <div className={classes.info2}>
              <img src={vector} alt="vector_icon" /> Piscine :{" "}
              <span className={classes.value}>
                {project.pool ? "Oui" : "Non"}
              </span>
            </div>
          </div>
        </div>
      </Col>
      <Col
        xxl={5}
        lg={6}
        md={12}
        sm={12}
        className={`d-flex flex-column justify-content-start mt-sm-2`}
      >
        <div className={`${classes.description}`}>
          <p className={`${classes.description_title}`}>
            <img src={Arrow} alt="Arrow" className={classes.arrow_img} />
            <img src={Arrow} alt="Arrow" className={classes.arrow_img} />{" "}
            Description :
          </p>
          <div
            className={`${classes.description_text}`}
            dangerouslySetInnerHTML={{ __html: project.description }}
          />
        </div>
      </Col>
    </Row>
  );
}
export default ProjectDetails;
