import React from "react";
import classes from "./ProjectCard.module.css";
import LP from "./../../assets/images/projects/LocationPin.svg";

function ProjectCard({ imageSrc, title, location, year, surface, type }) {
  return (
    <div className={classes.project_card}>
      <div className={classes.image_container}>
        <img
          src={`${process.env.REACT_APP_BASE_URL}/storage/${imageSrc}`}
          alt={title}
          className={classes.image}
        />
      </div>
      <div className={classes.content}>
        <h2 className={classes.title}>{title}</h2>
        <p>
          <img src={LP} alt="Location Pin" className={classes.location_icon} />
          <span className={classes.text}>{location}</span>
          <span className={classes.separator}> | </span>
          <span className={classes.text}>{type}</span>
        </p>
        <p className={classes.text}>Année de construction {year}</p>
        <p className={classes.text}>
          Surface {surface} M<sup>2</sup>
        </p>
      </div>
    </div>
  );
}

export default ProjectCard;
