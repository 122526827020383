import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Alert } from "react-bootstrap";
import classes from "./Contact.module.css";
import SlsTitle from "./../UI/SlsTitle";
import ContactInput from "../UI/ContactInput";
import LandlinePhone from "./../../assets/images/landline_phone.svg";
import MobilePhone from "./../../assets/images/mobile_phone.svg";
import Envelope from "./../../assets/images/envelope.svg";
import Location from "./../../assets/images/location.svg";
import Facebook from "./../../assets/images/socilas/Facebook.svg";
import Instagram from "./../../assets/images/socilas/Insta.svg";
import TikTok from "./../../assets/images/socilas/TikTok.svg";

function Contact() {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    telephone: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessages, setErrorMessages] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    fetch(`${process.env.REACT_APP_API_BASE_URL}/contact/submit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then(async (response) => {
        const data = await response.json();
        if (response.ok) {
          setErrorMessages("");
          setSuccessMessage(data.message || "Message envoyé avec succès !");
          setFormData({
            first_name: "",
            last_name: "",
            email: "",
            telephone: "",
            message: "",
          });
        } else {
          setSuccessMessage(
            `Erreur: ${data.message || "Une erreur s'est produite"}`
          );
          if (data.errors) {
            setErrorMessages(data.errors);
          }
        }
      })
      .catch((error) => {
        console.error("Erreur réseau:", error);
        setSuccessMessage(
          "Une erreur réseau s'est produite. Veuillez réessayer."
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="p-lg-0 p-1">
      <SlsTitle
        title="Contactez nous"
        text="Notre équipe est là pour vous aider rapidement et vous accompagner dans votre projet immobilier."
      />
      <Container className={`${classes.contact_container}`}>
        <Row className={`${classes.contact_row} d-flex `}>
          <Col className="p-0" lg={5} md={6} sm={12}>
            <div className={`h-100 py-5 ${classes.contact_infos}`}>
              <h1 className={classes.contact_title}>
                Les Informations de contact
              </h1>
              <p className={classes.contact_text}>
                Dites quelque chose pour démarrer une discussion.
              </p>
              <p className={classes.contact_info}>
                <img
                  src={LandlinePhone}
                  alt={"Landline Phone"}
                  className={classes.contact_icon}
                />
                <a className={`${classes.links}`} href="tel:0536681212">
                  05 36 68 12 12
                </a>
              </p>
              <p className={classes.contact_info}>
                <img
                  src={LandlinePhone}
                  alt={"Landline Phone"}
                  className={classes.contact_icon}
                />
                <a className={`${classes.links}`} href="tel:0536681415">
                  05 36 68 14 15
                </a>
              </p>
              <p className={classes.contact_info}>
                <img
                  src={MobilePhone}
                  alt={"Mobile Phone"}
                  className={classes.contact_icon}
                />
                <a className={`${classes.links}`} href="tel:0673207676">
                  06 73 20 76 76
                </a>
              </p>
              <p className={classes.contact_info}>
                <img
                  src={Envelope}
                  alt={"Envelope"}
                  className={classes.contact_icon}
                />
                <a
                  className={`${classes.links}`}
                  href="mailto:contact@sls-immobilier.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  contact@sls-immobilier.com
                </a>
              </p>
              <p className={classes.contact_info}>
                <img
                  src={Location}
                  alt={"Location"}
                  className={classes.contact_icon}
                />
                <a
                  className={`${classes.links}`}
                  href="https://shorturl.at/Nl2PQ"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Boulevard le prince Hériter Moulay El Hassan N°76 2ème étage,
                  Oujda 60000
                </a>
              </p>
              <div className="mt-5">
                <Col lg={6} md={8} sm={8} xs={8} className="d-flex">
                  <Link
                    to="https://web.facebook.com/sofiasls"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={Facebook}
                      alt="facebook logo"
                      className={classes.icon}
                    />
                  </Link>
                  <Link
                    to="https://www.instagram.com/slsimmobilier"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={Instagram}
                      alt="instagram logo"
                      className={classes.icon}
                    />
                  </Link>
                  <Link
                    to="https://www.tiktok.com/@slsimmob"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={TikTok}
                      alt="TikTok logo"
                      className={classes.icon}
                    />
                  </Link>
                </Col>
              </div>
              <div className={classes.circles}>
                <div className={classes.circle_1}></div>
                <div className={classes.circle_2}></div>
              </div>
            </div>
          </Col>
          <Col lg={7} md={6} sm={12} className="mt-5 px-lg-5 p-sm-3">
            {successMessage !== "" ? (
              <Alert variant={errorMessages !== "" ? "danger" : "success"}>
                <p>{successMessage}</p>
                {errorMessages && (
                  <ul>
                    {errorMessages.first_name && (
                      <li>{errorMessages.first_name}</li>
                    )}
                    {errorMessages.last_name && (
                      <li>{errorMessages.last_name}</li>
                    )}
                    {errorMessages.email && <li>{errorMessages.email}</li>}
                    {errorMessages.telephone && (
                      <li>{errorMessages.telephone}</li>
                    )}
                    {errorMessages.message && <li>{errorMessages.message}</li>}
                  </ul>
                )}
              </Alert>
            ) : (
              ""
            )}

            <form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col>
                  <ContactInput
                    label="Prénom"
                    name="first_name"
                    placeholder="Abde"
                    value={formData.first_name}
                    onChange={handleChange}
                  />
                </Col>
                <Col>
                  <ContactInput
                    label="Nom"
                    name="last_name"
                    placeholder="Raissouni"
                    value={formData.last_name}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <ContactInput
                    label="Email"
                    type="email"
                    name="email"
                    placeholder="contact@sls-immobilier.com"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Col>
                <Col>
                  <ContactInput
                    label="Numéro de téléphone"
                    type="tel"
                    name="telephone"
                    placeholder="+212 6456 789"
                    value={formData.telephone}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <ContactInput
                    label="Message"
                    textarea
                    name="message"
                    placeholder="Bonjour, ..."
                    value={formData.message}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-lg-end justify-content-md-end justify-content-sm-center p-0">
                  <button type="submit" className={`${classes.send_btn}`}>
                    {isSubmitting ? "Envoi en cours..." : "Envoyer le message"}
                  </button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Contact;
