import React from "react";
import { Outlet } from "react-router-dom";
import PublicNavbar from "../components/home/header/PublicNavbar";
import Footer from "../components/footer/Footer";

function PublicLayout() {
  return (
    <>
      <PublicNavbar />
      <Outlet />
      <Footer />
    </>
  );
}

export default PublicLayout;
