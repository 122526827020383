import React from "react";
import classes from "./ScroolUp.module.css";
import { Image } from "react-bootstrap";
import upArrow from "./../../assets/images/up-arrow.svg";

function ScroolUp() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className={classes.up_arrow}>
      <button onClick={scrollToTop} className={classes.up_arrow_btn}>
        <Image src={upArrow} className={classes.up_arrow_icon} alt="up-arrow" />
      </button>
    </div>
  );
}

export default ScroolUp;
