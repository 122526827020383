import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row, Spinner } from "react-bootstrap";
import SlsTitle from "./../UI/SlsTitle";
import { Link } from "react-router-dom";
import ProjectCard from "../UI/ProjectCard";

function Projects() {
  const [projectsData, setProjectsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/projects`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch projects.");
        }
        return response.json();
      })
      .then((data) => {
        setProjectsData(data.projects);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  return (
    <Container fluid>
      <SlsTitle
        title="Nos Projets"
        text="Nous réalisons une gamme variée de projets immobiliers qui allient qualité, innovation et esthétisme. "
      />
      <Row className="p-3 p-lg-5 d-flex justify-content-center align-items-stretch">
        {loading ? (
          <Col className="text-center mt-5">
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        ) : error ? (
          <Col className="text-center mt-5">
            <Alert variant="danger">{error}</Alert>
          </Col>
        ) : projectsData.length > 0 ? (
          projectsData.map((project, index) => (
            <Col
              key={index}
              lg={4}
              md={6}
              sm={12}
              className="d-flex justify-content-center align-items-stretch"
            >
              <div className="d-flex flex-column" style={{ width: "100%" }}>
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/project-details/${project.id}`}
                >
                  <ProjectCard
                    imageSrc={project.image_src}
                    title={project.title}
                    location={project.location}
                    type={project.type}
                    year={project.year}
                    surface={project.surface}
                  />
                </Link>
              </div>
            </Col>
          ))
        ) : (
          <Col className="text-center mt-5"></Col>
        )}
      </Row>
    </Container>
  );
}

export default Projects;
