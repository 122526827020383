import React from "react";
import classes from "./MemberCard.module.css";

function MemberCard({ image, name, role }) {
  return (
    <section className={classes.member_card}>
      <div className={classes.image_container}>
        <img src={image} alt={name} />
      </div>
      <div className={classes.member_info}>
        <h1 className={classes.member_role}>{role}</h1>
        <h4 className={classes.member_name}>{name}</h4>
      </div>
    </section>
  );
}

export default MemberCard;
