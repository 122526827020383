import React from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import classes from "./SocialLinks.module.css";
import facebook from "./../../../assets/images/white-facebook.svg";
import instagram from "./../../../assets/images/white-instagram.svg";
import WhiteTiktok from "./../../../assets/images/white-tiktok.svg";

function SocialLinks() {
  return (
    <div className={classes.social_links}>
      <Link
        to="https://web.facebook.com/sofiasls"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image src={facebook} className={classes.icon} alt="Facebook" />
      </Link>
      <Link
        to="https://www.instagram.com/slsimmobilier"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image
          style={{ width: "1.5vw" }}
          src={instagram}
          className={classes.icon}
          alt="Instagram"
        />
      </Link>
      <Link
        to="https://www.tiktok.com/@slsimmob"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image src={WhiteTiktok} className={classes.icon} alt="Tiktok" />
      </Link>
    </div>
  );
}

export default SocialLinks;
