import React, { useState } from "react";
import classes from "./Carousel.module.css";
import Slider from "react-slick";

export default function Carousel({ images, onImageClick }) {
  const [selectedImage, setSelectedImage] = useState(images[0]);

  const handleImageClick = (img) => {
    setSelectedImage(img);
    onImageClick(img);
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };

  return (
    <Slider {...settings}>
      {images.map((img, index) => (
        <div
          key={index}
          className={classes.img_container}
          onClick={() => handleImageClick(img)}
        >
          <img
            src={img}
            alt={`img ${index}`}
            className={selectedImage === img ? classes.bordered : ""}
          />
        </div>
      ))}
    </Slider>
  );
}
