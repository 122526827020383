import React from "react";
import classes from "./HeroContent.module.css";
function HeroContent() {
  return (
    <>
      <div className={classes.title_container}>
        <h1 className={classes.title}>
          S.L.S
          <br />
          IMMOBILIER
        </h1>
      </div>
      <div className={`${classes.subtitle_container}`}>
        <h1 className={classes.subtitle}>Promoteur Immobilier</h1>
        <p className={classes.description}>
          Située au cœur d'Oujda, notre entreprise se spécialise dans le domaine
          immobilier. Nous avons un riche portefeuille de projets déjà réalisés,
          ainsi que d'autres en cours d'adoption. Notre objectif est de vous
          offrir entière satisfaction grâce à nos projets et à notre service.
          Vous pouvez les découvrir sur notre site web.
        </p>
      </div>
      <div className={classes.hours}>Lundi - Vendredi : 09:00 - 18:00</div>
    </>
  );
}

export default HeroContent;
