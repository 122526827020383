import React from "react";
import classes from "./TestimonialCard.module.css";

function TestimonialCard({ testimonialName, testimonialImage, testimonial }) {
  return (
    <div className={classes.card}>
      <h1 className={classes.name}>{testimonialName}</h1>
      <div className={classes.image_container}>
      </div>
      <p className={classes.testimonial}>{testimonial}</p>
    </div>
  );
}

export default TestimonialCard;
