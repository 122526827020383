import React, { forwardRef } from "react";
import classes from "./ContactInput.module.css";

const ContactInput = forwardRef(function Input(
  { label, placeholder, type, textarea, ...props },
  ref
) {
  return (
    <div className={classes.container}>
      <label className={classes.label}>{label}</label>
      {textarea ? (
        <textarea
        required
          ref={ref}
          className={classes.input}
          {...props}
          placeholder={placeholder}
        />
      ) : (
        <input
        required
          ref={ref}
          className={classes.input}
          type={type}
          placeholder={label}
          {...props}
        />
      )}
    </div>
  );
});

export default ContactInput;
