import React from "react";
import SlsTitle from "./../../UI/SlsTitle";
import { Container, Row, Col } from "react-bootstrap";
import classes from "./AboutUs.module.css";
import AboutUsImg from "./../../../assets/images/about-us.png";

function AboutUs() {
  return (
    <section className={`mt-0 ${classes.about_us_container}`}>
      <SlsTitle
        title="À propos de nous"
        text="Basés à Oujda, nous sommes experts en immobilier, proposant des projets de qualité qui répondent à vos besoins. Notre équipe est là pour vous accompagner à chaque étape."
      />
      <Container>
        <Row >
          <Col
            lg={6}
            md={12}
            sm={12}
            className="d-flex justify-content-end position-relative "
          >
            <div className={classes.image_container}>
              <div className={classes.color_rectangle}></div>
              <div className={classes.white_rectangle}>
                <img src={AboutUsImg} alt="About us img" />
                <div className={classes.bordered_square}></div>
                <div className={classes.black_square}></div>
              </div>
            </div>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            className="d-flex flex-column justify-content-center align-items-start"
          >
            <div className={`w-100 ${classes.text_container}`}>
              <h1 className={classes.about_title}>
                Notre Engagement envers l'Immobilier{" "}
              </h1>
              <div className="">
                <div className={`position-relative ${classes.about_text}`}>
                  <p>
                    Bienvenue sur notre site ! Située au cœur d'Oujda, notre
                    entreprise se consacre à l'immobilier avec passion et
                    professionnalisme. Forts d'une expérience riche, nous avons
                    réalisé de nombreux projets qui allient qualité, innovation
                    et satisfaction client. Notre équipe dynamique est là pour
                    vous accompagner à chaque étape de votre projet, en vous
                    offrant des solutions adaptées à vos besoins. Découvrez nos
                    offres et engagez-vous avec nous pour donner vie à vos
                    aspirations immobilières. Votre satisfaction est notre
                    priorité !
                  </p>
                  <div className={classes.box_container}>
                    <div className={`${classes.box} ${classes.b1}`}></div>
                    <div className={`${classes.box} ${classes.b2}`}></div>
                    <div className={`${classes.box} ${classes.b3}`}></div>
                    <div className={`${classes.box} ${classes.b4}`}></div>
                    <div className={`${classes.box} ${classes.b5}`}></div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AboutUs;
