import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import classes from "./ChooseUs.module.css";
import SlsTitle from "../../UI/SlsTitle";
import Feature from "../../UI/Feature";
import qualityImg from "./../../../assets/images/quality.svg";
import trustImg from "./../../../assets/images/trust.svg";
import professionalismImg from "./../../../assets/images/professionalism.svg";

function ChooseUs() {
  const [statistics, setStatistics] = useState({
    clients: 100,
    yearsOfExperience: 10,
    projects: 100,
    partners: 10,
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/statistics`)
      .then((response) => response.json())
      .then((data) => setStatistics(data))
      .catch((error) => console.error("Error fetching statistics:", error));
  }, []);

  return (
    <section className={`mt-5 ${classes.Choose_us}`}>
      <SlsTitle
        title="Pourquoi nous choisir"
        text="Basés à Oujda, nous sommes experts en immobilier. Notre expérience et notre engagement garantissent des projets de qualité. Faites-nous confiance pour concrétiser vos rêves !"
      />
      <Container fluid className={classes.stats_container}>
        <div className={classes.overlay}></div>
        <Row className="d-flex justify-content-center align-items-center text-center p-5">
          <Col lg={3} md={6} sm={12}>
            <div className={classes.stat_container}>
              <h1 className={classes.stats_numbers}>+{statistics.clients}</h1>
              <p className={classes.stats_texts}>clients satisfaits</p>
            </div>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <div className={classes.stat_container}>
              <h1 className={classes.stats_numbers}>
                +{statistics.yearsOfExperience}
              </h1>
              <p className={classes.stats_texts}>années d'expérience</p>
            </div>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <div className={classes.stat_container}>
              <h1 className={classes.stats_numbers}>+{statistics.projects}</h1>
              <p className={classes.stats_texts}>projets terminés</p>
            </div>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <div className={classes.stat_container}>
              <h1 className={classes.stats_numbers}>+{statistics.partners}</h1>
              <p className={classes.stats_texts}>nos partenaires</p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row className="d-flex justify-content-center align-items-center text-center p-5">
          <Col lg={3} md={6} sm={12}>
            <Feature
              featureTitle="qualité"
              featureImgSrc={qualityImg}
              featureText="Notre engagement envers l'excellence se reflète dans tous nos projets, garantissant des résultats qui dépassent vos attentes."
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Feature
              featureTitle="confiance"
              featureImgSrc={trustImg}
              featureText="Nous bâtissons des relations solides avec nos clients, en assurant transparence et communication à chaque étape."
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Feature
              featureTitle="Professionnalisme"
              featureImgSrc={professionalismImg}
              featureText="Notre équipe expérimentée est dédiée à vous fournir un service de haute qualité, avec une attention particulière aux détails et aux besoins de nos clients."
            />
          </Col>
        </Row>
      </Container>
      <Container className="position-relative">
        <div className={classes.left_square}>
          <div className={classes.bordered_square}></div>
          <div className={classes.black_square}></div>
        </div>
        <div className={classes.right_square}>
          <div className={classes.square_1}></div>
          <div className={classes.square_2}></div>
          <div className={classes.square_3}></div>
          <div className={classes.square_4}></div>
        </div>
      </Container>
    </section>
  );
}

export default ChooseUs;
