import React from "react";
import classes from "./Feature.module.css";

function Feature({ featureTitle, featureImgSrc, featureText }) {
  return (
    <div className={classes.feature_container}>
      <div className={classes.feature_square}>
        <img src={featureImgSrc} alt={featureTitle} />
      </div>
      <h2 className={classes.feature_title}>{featureTitle}</h2>
      <p className={classes.feature_text}>{featureText}</p>
    </div>
  );
}

export default Feature;
