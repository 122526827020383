import React, { useEffect } from "react";
import Projects from "../components/projects/Projects";


function ProjectsPage() {
  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Projects />
    </>
  );
}

export default ProjectsPage;
