import React, { useEffect } from "react";
import AboutUs from "../components/about/about-us/AboutUs";
import ChooseUs from "./../components/about/choose-us/ChooseUs";
import OurCompany from "../components/about/company/OurCompany";
import Team from "../components/about/team/Team";
import Partners from "../components/about/partners/Partners";


function AboutPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <AboutUs />
      <ChooseUs />
      <OurCompany />
      <Team />
      <Partners />
      
    </>
  );
}

export default AboutPage;
