import React from "react";
import SlsTitle from "./../../UI/SlsTitle";
import { Container, Row, Col } from "react-bootstrap";
import TestimonialCard from "./../../UI/TestimonialCard";

const testimonials = [
  {
    name: "KHADIJA K.",
    text: "Un service exceptionnel ! Les projets sont de qualité et l'équipe est toujours à l'écoute. Je recommande vivement !",
  },
  {
    name: "MOHAMED M.",
    text: "Je suis ravi de mon expérience avec cette entreprise. Chaque étape a été fluide, et le résultat final dépasse mes attentes",
  },
  {
    name: "MARIAM K.",
    text: "Une vraie référence dans l'immobilier à Oujda ! Leur professionnalisme et leur engagement envers les clients sont remarquables.",
  },
];

function Testimonials() {
  return (
    <Container fluid>
      <SlsTitle title="Témoignages" />
      <Container>
        <Row className="d-flex">
          {testimonials.map((testimonial, index) => (
            <Col
              key={index}
              lg={4}
              md={6}
              sm={12}
              className="d-flex align-items-stretch"
            >
              <TestimonialCard
                testimonialName={testimonial.name}
                testimonial={testimonial.text}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
}

export default Testimonials;
