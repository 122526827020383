import React from "react";
import classes from "./FilterSection.module.css";

function FilterSection({
  filters,
  onFilterChange,
  typeOptions,
  locationOptions,
  surfaceOptions,
}) {
  return (
    <section className="text-center">
      <div className={classes.filter_section}>
        <p className={classes.filter_by}>TRIER PAR :</p>
        <div className={classes.filters_container}>
          <div>
            <select
              name="type"
              className={classes.select}
              value={filters.type}
              onChange={onFilterChange}
              title="trier par type"
            >
              <option value="">Type</option>
              {typeOptions.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <div>
            <select
              name="space"
              className={classes.select}
              value={filters.space}
              onChange={onFilterChange}
              title="trier par espace"
            >
              <option value="">Espace</option>
              {locationOptions.map((location, index) => (
                <option key={index} value={location}>
                  {location}
                </option>
              ))}
            </select>
          </div>
          <div>
            <select
              name="surface"
              className={classes.select}
              value={filters.surface}
              onChange={onFilterChange}
              title="trier par superficie"
            >
              <option value="">Superficie</option>
              {surfaceOptions.map((surface, index) => (
                <option key={index} value={surface}>
                  {surface}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FilterSection;
