import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Spinner } from "react-bootstrap";
import classes from "./ProjectGallery.module.css";
import phone from "./../../assets/images/24phone.svg";
import Carousel from "./../UI/Carousel.jsx";
import Plan from "./Plan.jsx";
import Arrow from "./../../assets/images/PeojectDetailsArrow.svg";

function ProjectGallery() {
  const param = useParams();
  const [project, setProject] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeSection, setActiveSection] = useState("details");
  const [mainImage, setMainImage] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    telephone: "",
    email: "",
    subject: "",
    message: "",
  });

  const [status, setStatus] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/projects`
        );
        if (!response.ok) {
          throw new Error("Erreur lors de la récupération des projets.");
        }
        const data = await response.json();
        const filteredProject = data.projects.find(
          (proj) => proj.id === parseInt(param.id, 10)
        );
        if (filteredProject) {
          setProject(filteredProject);
          setMainImage(
            `${process.env.REACT_APP_BASE_URL}/storage/${filteredProject?.image_src}`
          );
        } else {
          throw new Error("Projet non trouvé.");
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [param.id]);

  const handleImageClick = (img) => {
    setMainImage(img);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus(null);
    setIsSubmitting(true);
    const { name, telephone, email, subject, message } = formData;
    if (
      name === "" ||
      telephone === "" ||
      email === "" ||
      subject === "" ||
      message === ""
    ) {
      setStatus({
        success: false,
        message: "Tous les champs doivent être remplis.",
      });
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/project/mail`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        setStatus({
          success: true,
          message: "Formulaire soumis avec succès!",
        });
        setFormData({
          name: "",
          telephone: "",
          email: "",
          subject: "",
          message: "",
        });
      } else {
        setStatus({
          success: false,
          message: "Échec de la soumission du formulaire. Veuillez réessayer.",
        });
      }
    } catch (error) {
      setStatus({
        success: false,
        message: "Une erreur est survenue lors de la soumission.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <div className="text-center text-danger">Erreur : {error}</div>;
  }

  if (!project) {
    return <div className="text-center">Projet non trouvé.</div>;
  }

  const images = JSON.parse(project.images);
  const allImages = [
    `${process.env.REACT_APP_BASE_URL}/storage/${project.image_src}`,
    ...images.map((img) => `${process.env.REACT_APP_BASE_URL}/storage/${img}`),
  ];

  return (
    <>
      <div className="p-lg-5 p-1 pb-1 d-flex justify-content-center">
        <Row className={`d-flex justify-content-center  ${classes.container}`}>
          <div className={`${classes.title}`}>
            <p>
              <img src={Arrow} alt="Arrow" />
              <img src={Arrow} alt="Arrow" />
              <span className={`${classes.title_text}`}>Détails projet</span>
            </p>
          </div>
          <Col
            xxl={5}
            lg={6}
            md={12}
            sm={12}
            className={`d-flex align-items-stretch justify-content-center `}
          >
            <div className={`h-100 ${classes.images_container}`}>
              <div className={`${classes.buttons} p-0 ml-0 text-start w-100`}>
                <button
                  className={`${
                    activeSection === "details"
                      ? classes.active_details_btn
                      : classes.details_btn
                  }`}
                  onClick={() => setActiveSection("details")}
                >
                  Détails du projet
                </button>
                <button
                  className={`${
                    activeSection === "plan"
                      ? classes.active_details_btn
                      : classes.details_btn
                  }`}
                  onClick={() => setActiveSection("plan")}
                >
                  Plan du projet
                </button>
              </div>
              {activeSection === "details" && (
                <div>
                  <div className={classes.main_image_container}>
                    <img
                      src={mainImage}
                      alt="appartement"
                      className={`${classes.main_img} rounded`}
                    />
                  </div>
                  <Carousel
                    images={allImages}
                    onImageClick={handleImageClick}
                  />
                </div>
              )}
              {activeSection === "plan" && (
                <Plan
                  planImageSrc={`${process.env.REACT_APP_BASE_URL}/storage/${project.plan_image_src}`}
                />
              )}
            </div>
          </Col>
          <Col
            xxl={5}
            lg={6}
            md={12}
            sm={12}
            className={`d-flex align-items-stretch justify-content-lg-start justify-content-center`}
          >
            <div className={`${classes.card}`}>
              <form
                className={`${classes.form_content}`}
                onSubmit={handleSubmit}
              >
                <div className={`${classes.number}`}>
                  <img src={phone} alt="phone" />
                  <span className={`${classes.separator}`}>|</span>
                  <span>
                    <a href="tel:0673207676">+212 673 20 76 76</a>
                  </span>
                </div>
                {status && (
                  <div
                    className={
                      status.success
                        ? `w-100 alert alert-success ` + classes.successMessage
                        : `w-100 alert alert-danger ` + classes.errorMessage
                    }
                    role="alert"
                  >
                    {status.message}
                  </div>
                )}
                <input
                  name="name"
                  className={`${classes.form_input}`}
                  type="text"
                  placeholder="Nom et prénom"
                  value={formData.name}
                  onChange={handleInputChange}
                />
                <input
                  name="telephone"
                  className={`${classes.form_input}`}
                  type="text"
                  placeholder="Téléphone"
                  value={formData.telephone}
                  onChange={handleInputChange}
                />
                <input
                  name="email"
                  className={`${classes.form_input}`}
                  type="email"
                  placeholder="E-mail"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                <input
                  name="subject"
                  className={`${classes.form_input}`}
                  type="text"
                  placeholder="Sujet"
                  value={formData.subject}
                  onChange={handleInputChange}
                />
                <textarea
                  name="message"
                  className={`${classes.textarea}`}
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleInputChange}
                  rows={4}
                ></textarea>
                <button type="submit" className={`${classes.send_btn}`}>
                  {isSubmitting ? "Envoi en cours..." : "Envoyer"}
                </button>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ProjectGallery;
