import React from "react";
import classes from "./SlsTitle.module.css";

function SlsTitle({ title, text }) {
  return (
    <div className="text-center my-5">
      <div className={classes.title_container}>
        <h1 className={classes.title}>{title}</h1>
        <div className="">
          {text && <p className={`text-center ${classes.text}`}>{text}</p>}
        </div>
      </div>
    </div>
  );
}

export default SlsTitle;
