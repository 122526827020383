import React from "react";
import classes from "./Plan.module.css";
function Plan({ planImageSrc }) {
  return (
    <section className="text-center">
      <img
        src={planImageSrc}
        alt="Plan"
        className={`fluid rounded ${classes.img_thumbnail}`}
      />
    </section>
  );
}

export default Plan;
