import React from "react";
import classes from "./FeatureCard.module.css";

function FeatureCard({ title, imgSrc }) {
  return (
    <div className={classes.feature_card_wrapper}>
      <div className={classes.feature_card_container}>
        <img src={imgSrc} alt="Feature" />
        <h1 className={classes.feature_title}>{title}</h1>
      </div>
      <div className={classes.red_border_card}></div>
    </div>
  );
}

export default FeatureCard;
