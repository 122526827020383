import React, { useEffect } from "react";
import Hero from "./../components/home/hero/Hero";
import Projects from "../components/home/Projects/Projects";
import StatsOverview from "../components/home/stats/StatsOverview";
import RequestCall from "../components/home/call/RequestCall";
import Testimonials from "../components/home/testimonials/Testimonials";


function HomePage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Hero />
      <Projects />
      <StatsOverview />
      <RequestCall />
      <Testimonials />
    </>
  );
}

export default HomePage;
