import React from "react";
import { Col, Image } from "react-bootstrap";
import classes from "./Hero.module.css";
import HeroContent from "./HeroContent";
import hero from "./../../../assets/images/hero_img.png";
import SocialLinks from "./SocialLinks";

function Hero() {
  return (
    <div className={classes.hero}>
      <div className={` ${classes.hero_container}`}>
        <Col lg={6} className={`mt-5 ${classes.content_container}`}>
          <HeroContent />
        </Col>
        <Col lg={6} className="d-none d-lg-block">
          <SocialLinks />
          <div className={classes.image_container}>
            <Image
              style={{ height: "100%", width: "100%" }}
              className={classes.hero_img}
              src={hero}
              fluid
              alt="Modern architecture"
            />
          </div>
        </Col>
      </div>
      <div className="my-5 pr-0">
        <div>
          <h1 className={`text-center ${classes.hero_heading}`}>
            Nous plaçons nos utilisateurs et la qualité de notre produit <br />{" "}
            au cœur de nos préoccupations.
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Hero;
