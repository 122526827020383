import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Alert, Spinner } from "react-bootstrap";
import classes from "./SimilarListings.module.css";
import ProjectCard from "./../UI/ProjectCard";

function SimilarListings() {
  const param = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [similarProjects, setSimilarProjects] = useState([]);

  const handleClick = (id) => {
    navigate(`/project-details/${id}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/projects`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch projects.");
        }
        const data = await response.json();

        const currentProject = data.projects.find(
          (project) => project.id === parseInt(param.id)
        );

        if (currentProject) {
          let filteredProjects = data.projects.filter(
            (project) =>
              project.type === currentProject.type &&
              project.id !== currentProject.id
          );
          if (filteredProjects.length < 3) {
            const additionalProjects = data.projects.filter(
              (project) =>
                (project.surface === currentProject.surface ||
                  project.location === currentProject.location) &&
                project.id !== currentProject.id &&
                !filteredProjects.includes(project)
            );

            filteredProjects = [...filteredProjects, ...additionalProjects];
          }
          if (filteredProjects.length >= 3) {
            filteredProjects = filteredProjects.slice(0, 3);
          } else {
            const remainingProjects = data.projects.filter(
              (project) =>
                project.id !== currentProject.id &&
                !filteredProjects.includes(project)
            );
            filteredProjects = [
              ...filteredProjects,
              ...remainingProjects.slice(0, 3 - filteredProjects.length),
            ];
          }
          setSimilarProjects(filteredProjects);
        }
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    fetchProjects();
  }, [param.id]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <div className="text-center text-danger">Erreur : {error}</div>;
  }

  return (
    <Container
      fluid
      className="p-5 mb-5 d-flex align-items-center justify-content-center"
    >
      <Row className="p-0 p-lg-5 justify-content-center align-items-stretch ">
        <div className={classes.similar_title}>
          <h1>Similar listings</h1>
          <span className={classes.gradient_line}></span>
        </div>
        {similarProjects.length > 0 ? (
          similarProjects.map((project, index) => (
            <Col
              key={index}
              lg={4}
              md={6}
              sm={12}
              className="d-flex justify-content-center align-items-stretch"
            >
              <div className="d-flex flex-column" style={{ width: "100%" }}>
                <span
                  style={{ textDecoration: "none", cursor: "pointer" }}
                  onClick={() => handleClick(project.id)}
                >
                  <ProjectCard
                    imageSrc={project.image_src}
                    title={project.title}
                    location={project.location}
                    type={project.type}
                    year={project.year}
                    surface={project.surface}
                  />
                </span>
              </div>
            </Col>
          ))
        ) : (
          <Col className="text-center mt-5">
            <Alert className="alert-danger text-danger">
              Aucun projet ne correspond aux filtres sélectionnés.
            </Alert>
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default SimilarListings;
