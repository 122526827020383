import React, { useState } from "react";
import classes from "./RequestCall.module.css";
import SlsInput from "../../UI/SlsInput";
import Phone from "./../../../assets/images/phone.svg";

function RequestCall() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const submitPhoneNumber = async () => {
    if (!phoneNumber) {
      setResponseMessage("Veuillez entrer un numéro de téléphone");
      setIsError(true);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/store-phone`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone_number: phoneNumber,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setResponseMessage(
          data.message || "Numéro de téléphone soumis avec succès !"
        );
        setIsError(false);
        setPhoneNumber("");
      } else {
        if (response.status === 409) {
          setResponseMessage(
            data.message ||
              "Ce numéro de téléphone est déjà dans nos enregistrements."
          );
        } else {
          setResponseMessage(
            data.message || "Échec de la soumission du numéro de téléphone"
          );
        }
        setIsError(true);
      }
    } catch (error) {
      console.error("Erreur lors de l’envoi du numéro de téléphone:", error);
      setResponseMessage("Erreur lors de l’envoi du numéro de téléphone");
      setIsError(true);
    }
  };

  return (
    <div
      className={`${classes.container} d-flex justify-content-center align-items-center text-center p-5`}
    >
      <SlsInput
        inputTitle="Entrez votre numéro de téléphone, et nous vous appellerons !"
        inputType="tel"
        inputPlaceholder="Votre numéro téléphone"
        inputIcon={Phone}
        onButtonClick={submitPhoneNumber}
        changeValue={(e) => setPhoneNumber(e.target.value)}
        responseMessage={responseMessage && <p>{responseMessage}</p>}
        isError={isError}
      />
    </div>
  );
}

export default RequestCall;
