import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import classes from "./StatsOverview.module.css";

function StatsOverview() {
  const [statistics, setStatistics] = useState({
    clients: 100,
    ouvriers: 100,
    projects: 100,
  });
  
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/statistics`)
      .then((response) => response.json())
      .then((data) => setStatistics(data))
      .catch((error) => console.error('Error fetching statistics:', error));
  }, []);

  return (
    <Container fluid className={classes.stats_container}>
      <Row className="d-flex justify-content-center align-items-center text-center p-5 text-white ">
        <Col lg={4} md={6} sm={12}>
          <h1 className={classes.stats_numbers}>+{statistics.clients}</h1>
          <p className={classes.stats_texts}>clients satisfaits</p>
        </Col>          <span className={classes.separator}></span>

        <Col lg={4} md={6} sm={12}>
          <h1 className={classes.stats_numbers}>+{statistics.ouvriers}</h1>
          <p className={classes.stats_texts}>ouvriers</p>
         

        </Col> <span className={classes.separator}></span>
        <Col lg={4} md={6} sm={12}>
          <h1 className={classes.stats_numbers}>+{statistics.projects}</h1>
          <p className={classes.stats_texts}>projets complets</p>
         
        </Col>
      </Row>
    </Container>
  );
}

export default StatsOverview;
