import React, { useEffect } from "react";
import ProjectGallery from "../components/project-details/ProjectGallery";
import ProjectDetails from "../components/project-details/ProjectDetails";
import SimilarListings from "../components/project-details/SimilarListings";

export default function ProjectsDetailsPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <ProjectGallery />
      <ProjectDetails />
      <SimilarListings />
    </>
  );
}
