import React from "react";
import classes from "./OurCompany.module.css";
import SlsTitle from "./../../UI/SlsTitle";
import { Container, Row, Col } from "react-bootstrap";
import FeatureCard from "../../UI/FeatureCard";
import FeatureImg from "./../../../assets/images/feature.png";

function OurCompany() {
  return (
    <section className="position-relative">
      <SlsTitle
        title="Notre compagnie"
        text="Notre compagnie immobilière allie confiance, qualité et professionnalisme. Nous vous accompagnons pour réaliser vos rêves avec des solutions personnalisées."
      />
      <Container>
        <Row>
          <Col
            lg={6}
            md={12}
            sm={12}
            className={`d-flex flex-column position-relative`}
          >
            <Row className={`mb-4 ${classes.feature_cards_container}`}>
              <Col>
                <FeatureCard title="MODERN" imgSrc={FeatureImg} />
              </Col>
              <Col>
                <FeatureCard title="ANCIENT" imgSrc={FeatureImg} />
              </Col>
            </Row>
            <Row className={`mb-4 ${classes.feature_cards_container}`}>
              <Col>
                <FeatureCard title="COLOUR" imgSrc={FeatureImg} />
              </Col>
              <Col>
                <FeatureCard title="ARTISTIC" imgSrc={FeatureImg} />
              </Col>
              <div className={classes.box_container}>
                <div className={`${classes.box} ${classes.b1}`}></div>
                <div className={`${classes.box} ${classes.b2}`}></div>
                <div className={`${classes.box} ${classes.b3}`}></div>
                <div className={`${classes.box} ${classes.b4}`}></div>
                <div className={`${classes.box} ${classes.b5}`}></div>
              </div>
            </Row>
          </Col>
          <Col lg={6} md={12} sm={12}>
            <div className={classes.company_content}>
              <h2 className={classes.company_title}>
                Créer des rêves, bâtir l'avenir.
              </h2>
              <p style={{ color: "#717171" }}>
                Elle se spécialise dans l'immobilier avec un engagement profond
                envers la confiance, la qualité et le professionnalisme. Forts
                d'une riche expérience, nous avons réalisé de nombreux projets
                qui témoignent de notre capacité à répondre aux besoins de nos
                clients. Notre équipe dynamique et passionnée est à votre écoute
                pour vous accompagner à chaque étape, vous assurant un service
                personnalisé et des solutions adaptées. Choisissez-nous pour
                transformer vos aspirations immobilières en réalité.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default OurCompany;
