import React, { useState } from "react";
import { Container, Nav, Navbar, Image } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "./../../../assets/images/logo.svg";
import classes from "./PublicNavbar.module.css";

function PublicNavbar() {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const handleNavItemClick = () => {
    setExpanded(false);
  };
  const handleToggleClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Navbar
      expand="lg"
      expanded={expanded}
      onToggle={handleToggleClick}
      className={`${
        /\/project-details\/\d+/.test(location.pathname)
          ? classes.navbar_container_border
          : classes.navbar_container
      }`}
    >
      <Container fluid className="justify-content-between">
        <Link to="/">
          <Image
            fluid
            src={logo}
            alt="sls"
            className={`d-lg-none  ${classes.navbar_brand}`}
          />
        </Link>
        <div
          className={`${classes.navbar_toggle} ${expanded ? classes.open : ""}`}
          onClick={handleToggleClick}
        >
          <div className={classes.bar1}></div>
          <div className={classes.bar2}></div>
          <div className={classes.bar3}></div>
        </div>
        <div
          className={`${classes.navbar_menu} ${
            expanded ? classes.navbar_menu_expanded : ""
          }`}
        >
          <Navbar.Brand
            as={Link}
            to="/"
            className={`d-flex align-items-center`}
            onClick={handleNavItemClick}
          >
            <Image
              src={logo}
              alt="sls"
              className={`d-none d-lg-block ${classes.navbar_brand}`}
            />
          </Navbar.Brand>
          <Nav className={`ml-2 ${expanded ? "text-center" : ""}`}>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `${classes.nav_link_active} nav-link`
                  : `nav-link ${classes.nav_link}`
              }
              to="/"
              onClick={handleNavItemClick}
            >
              Accueil
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `${classes.nav_link_active} nav-link`
                  : `nav-link ${classes.nav_link}`
              }
              to="/about"
              onClick={handleNavItemClick}
            >
              À propos
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `${classes.nav_link_active} nav-link`
                  : `nav-link ${classes.nav_link}`
              }
              to="/projects"
              onClick={handleNavItemClick}
            >
              Projets
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? ` ${classes.nav_link_active} nav-link`
                  : `nav-link ${classes.nav_link}`
              }
              to="/contact"
              onClick={handleNavItemClick}
            >
              Contactez-nous
            </NavLink>
          </Nav>
          <div
            className={`${classes.navbar_contact} ${
              location.pathname === "/contact" ||
              /\/project-details\/\d+/.test(location.pathname)
                ? classes.navbar_contact_border
                : ""
            }`}
          >
            <p className="mb-0">
              <a
                className={`${classes.a}`}
                href="https://wa.me/0673207676"
                target="_blank"
                rel="noreferrer noopener"
              >
                +212 673 207 676
              </a>
            </p>
            <span
              className={`mx-2 ${
                location.pathname === "/"
                  ? classes.white_separator
                  : classes.separator
              }`}
            >
              |
            </span>
            <p className="mb-0">
              <a
                className={`${classes.a}`}
                href="mailto:contact@sls-immobilier.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                contact@sls-immobilier.com
              </a>
            </p>
          </div>
        </div>
      </Container>
    </Navbar>
  );
}

export default PublicNavbar;
