import React from "react";
import classes from "./SlsInput.module.css";
import { useLocation } from "react-router-dom";

function SlsInput({
  inputTitle,
  inputText,
  inputType,
  inputPlaceholder,
  inputIcon,
  onButtonClick,
  changeValue,
  responseMessage,
  isError,
}) {
  const location = useLocation();

  return (
    <div className="text-center">
      <h1
        className={`${classes.title} ${
          location.pathname !== "/projects"
            ? classes.title_gold
            : classes.title_blue
        }`}
      >
        {inputTitle}
      </h1>
      {inputText && <span className={classes.text}>{inputText}</span>}
      {responseMessage && (
        <span
          className={`${classes.text_msg} ${
            isError ? classes.error : classes.success
          }`}
        >
          {responseMessage}
        </span>
      )}

      <div className={`py-3 px-5  ${classes.input_container}`}>
        <input
          className={classes.input}
          type={inputType}
          placeholder={inputPlaceholder}
          onChange={changeValue}
        />
        <button className={classes.input_icon} onClick={onButtonClick}>
          <img src={inputIcon} alt="Phone" className="img-fluid" />
        </button>
      </div>
    </div>
  );
}

export default SlsInput;
