import React, { useState, useEffect } from "react";
import SlsInput from "../../UI/SlsInput";
import classes from "./SubscribeNewsletter.module.css";
import Send from "./../../../assets/images/send.svg";

function SubscribeNewsletter() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/newsletter`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        setMessage(
          result.message ||
            "L’e-mail a été ajouté avec succès à la newsletter !"
        );
        setIsError(false);
        setEmail("");
      } else {
        const errorResult = await response.json();
        setMessage(
          errorResult.message ||
            "Une erreur s’est produite. Veuillez réessayer."
        );
        setIsError(true);
      }
    } catch (error) {
      setMessage("Une erreur s’est produite. Veuillez réessayer.");
      setIsError(true);
    }
  };

  useEffect(() => {
    return () => {
      setEmail("");
      setMessage("");
      setIsError(false);
    };
  }, []);

  return (
    <div
      className={`${classes.container} d-flex justify-content-center align-items-center text-center p-5`}
    >
      <SlsInput
        inputTitle="Inscrivez-vous à notre newsletter !"
        inputText="Laissez votre email pour découvrir nos offres et nouveautés."
        inputType="email"
        inputPlaceholder="Votre email"
        inputIcon={Send}
        value={email}
        changeValue={(e) => setEmail(e.target.value)}
        onButtonClick={handleSubmit}
        responseMessage={message && <p>{message}</p>}
        isError={isError}
      />
    </div>
  );
}

export default SubscribeNewsletter;
